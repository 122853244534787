import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle: "SpotOn Case Study | Strow's Dry Cleaners",
    pageDescription:
      "Find out how a dry cleaning business increases sales and gets repeat business by leveraging SpotOn Loyalty's customizable rewards program.",
    title: "Getting more regulars with rewards",
    subTitle: `How Strow's Dry Cleaners gets repeat sales with SpotOn Loyalty.`,
    businessName: "Strow's Dry Cleaners",
    industry: "Retail",
    location: "Jacksonville, Florida",
    products: [spotonProducts.LOYALTY],
    description:
      "Seven years ago, Luke Strowbridge got the idea to start Strow's Dry Cleaners after picking up his wife's dry cleaning and chatting with the owner. Today, Strowbridge utilizes SpotOn Loyalty to encourage repeat visits, boost sales, and stay on top of his entire business.",
    quoteData: {
      imgName: "strows-dry-cleaners.png",
      quote: `“With SpotOn Loyalty, I can directly market to my VIPs. I love how it automatically
      sends them a deal for a free pair of pants to be dry cleaned. It saved me when I was sick and couldn’t be at work. Also, SpotOn’s automated marketing lets my customers know when we are open for business, so they keep coming back.”`,
      personName: "Luke Strowbridge",
      personTitle: "Owner, Strow’s Dry Cleaners",
    },
    goal: `Owner Luke Strowbridge needed to connect with customers, reward loyalty, and market to everyone that used his dry cleaning service. He also wanted to have easy access to his sales reports so he could spend less time on administration.`,
    solution: `With SpotOn Loyalty, Strowbridge boosts sales by capturing his customers' data and sending emails to them with personalized deals. On top of that, he can easily track sales data and find customer information without the hassle of spreadsheets.`,
    results: {
      title: `The results`,
      stats: [
        { title: `30%`, description: `increase in sales` },
        {
          title: `$1,500`,
          description: `savings in annual processing and software fee`,
        },
        {
            title: `1.5 hours`,
            description: `saved in monthly profit & loss analysis`,
          },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
      title: "A blooming nursery gives customers a faster experience",
      imageName: "unique-plants-and-palms.png",
      industry: resourceIndustries.RETAIL,
      tag: resourceTags.CASE_STUDY,
      date: "2022-07-13",
      href: "/case-studies/unique-plants-and-palms",
      goals: [
        resourceGoals.STREAMLINE_OPERATIONS,
        resourceGoals.GET_SMARTER_DATA,
        resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
      ],
    },
    {
      title: "Imagining gift baskets online",
      imageName: "imagine-that.png",
      industry: resourceIndustries.RETAIL,
      tag: resourceTags.CASE_STUDY,
      date: "2022-03-17",
      href: "/case-studies/imagine-that",
      goals: [
        resourceGoals.CONNECT_AND_SELL_ONLINE,
        resourceGoals.REACH_NEW_CUSTOMERS,
      ],
    },
    {
      title: "Growing awareness for autism",
      imageName: "ability-matters.png",
      industry: resourceIndustries.PROFESSIONAL_SERVICES,
      tag: resourceTags.CASE_STUDY,
      date: "2022-05-12",
      href: "/case-studies/ability-matters",
      goals: [
        resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
        resourceGoals.REACH_NEW_CUSTOMERS,
      ],
    },
  ];
  